import React from "react"
import GeneralComp from "../../components/generalComp"
import Layout from "../../components/layout"
import { branchCh } from "../../data/academics"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import { getWords } from "../../helper-functions"

function BranchChange(props) {
  console.log(props.data)
  return (
    <div>
      <Layout>
        <SEO
          title={branchCh.title}
          description={getWords(branchCh.content, 60)}
          image={props.data.file.childImageSharp.fluid.src}
        />
        <GeneralComp
          {...branchCh}
          fluid={props.data.file.childImageSharp.fluid}
        />
      </Layout>
    </div>
  )
}

export default BranchChange

export const query = graphql`
  {
    file(relativePath: { eq: "academics/branch-change.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
